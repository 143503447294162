import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import type { FC } from "react";
import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";

type Level = 1 | 2 | 3 | 4 | 5;

const Heading: FC<{
  title: string | React.ReactNode;
  icon?: React.ReactNode;
  level?: Level;
  tooltip?: string | null;
}> = ({ title = <InfoCircleOutlined />, level = 5 }) => (
  <Space align="center">
    <Typography.Title level={level} className="m-0 text-sm lg:text-lg">
      {title}
    </Typography.Title>
  </Space>
);

export default Heading;
