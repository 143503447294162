import React from "react";
import { Button, Popover } from "antd";
import NotSupportedPopover from "./NotSupportedPopover"; // Import the new component

const ButtonBuySell = ({ onBuy, onSell }: { onBuy?: () => void; onSell?: () => void }) => {
  return (
    <div className="flex gap-2">
      <Popover content={<NotSupportedPopover />} trigger="hover" title={null}>
        <Button className="flex-1 bg-[#52C41A] hover:bg-green-600 border-none text-black">Buy</Button>
      </Popover>
      <Popover content={<NotSupportedPopover />} trigger="hover" title={null}>
        <Button className="flex-1 bg-[#FF4D4F] hover:bg-red-600 border-none text-black">Sell</Button>
      </Popover>
    </div>
  );
};

export default ButtonBuySell;
