import React, { memo } from "react";
import { ArrowUpOutlined } from "@ant-design/icons";
import { PredictionStatus } from "@/models/prediction";
import classNames from "classnames";
import { Typography } from "antd/lib";
import { formatThousands } from "@/helpers";
import MyCard from "./MyCard";
import PriceChange from "@/app/components/PriceChange";
import { useCurrencyContext } from "@/context/CurrencyContext";

interface PredictionCardTimeProps {
  time: string;
  status: PredictionStatus;
  customStatus?: string;
  forecast: number;
  price?: number;
  customForecast?: string;
  headerBackground?: string;
}

const PredictionTimeCard: React.FC<PredictionCardTimeProps> = ({
  time,
  status,
  forecast,
  price,
  headerBackground,
  customStatus,
  customForecast,
}) => {
  const { currentCurrency } = useCurrencyContext();
  return (
    <MyCard
      className="shadow-xl border border-black border-opacity-10 min-w-[180px] rounded-lg h-fit"
      size="small"
      title={
        <Typography.Text className={classNames("text-center w-full", { "text-white": !!headerBackground })}>
          {time}
        </Typography.Text>
      }
      styles={{
        body: { padding: "8px 0px" },
        header: {
          textAlign: "center",
          backgroundColor: headerBackground ?? "#F5F5F5",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          paddingTop: "8px",
        },
        title: {
          width: "100%",
        },
      }}
    >
      <div className="flex flex-col items-center p-3 pb-0 gap-6 min-h-[135px]">
        <div className="flex items-center flex-col gap-2 text-sm sm:text-base">
          <Typography.Text>
            {customStatus ?? `Price will ${status === PredictionStatus.DECREASE ? "decrease down" : "increase up"} to`}
          </Typography.Text>

          <Typography.Text strong>
            {customForecast ?? formatThousands(forecast, currentCurrency)}{" "}
            {!customForecast ? price && <PriceChange price={price ? ((forecast - price) * 100) / price : 0} /> : ""}
          </Typography.Text>
        </div>
        {!customForecast ? (
          <ArrowUpOutlined
            className={`text-[48px] ${status === PredictionStatus.DECREASE ? "rotate-[-135deg]" : "rotate-45"}`}
          />
        ) : null}
      </div>
    </MyCard>
  );
};

export default memo(PredictionTimeCard);
