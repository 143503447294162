import type { IToken } from "./token";

export enum PredictionStatus {
  DECREASE = "decrease",
  INCREASE = "increase",
}

export interface IPrediction {
  tokens: IToken;
  forecast: number;
  status: PredictionStatus;
  timeframe: string;
  accuracy: number;
  price: number;
}

export type IPredictionResponse = IPrediction[];
