"use client";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CaretDownOutlined, CaretUpOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Empty, Select, Skeleton } from "antd/lib";

// Table column type definition
export interface MyColumnType<T> {
  title: string;
  dataIndex: string;
  key: string;
  render?: (value: any, record: T) => React.ReactNode;
  sorter?: ((a: T, b: T) => number) | boolean;
  className?: string;
  align?: "left" | "center" | "right";
  width?: number | string;
}

interface PaginationProps {
  pageSizeOptions?: number[];
  defaultPageSize?: number;
  totalRecords?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (size: number) => void;
  showPageSizeOptions?: boolean;
}

interface ScrollProps {
  y?: number;
  x?: number | string;
}

interface TableProps<T> {
  columns: MyColumnType<T>[];
  dataSource: T[];
  className?: string;
  pagination?: PaginationProps | boolean;
  scroll?: ScrollProps;
  isLoading?: boolean;
}

const isPaginationProps = (pagination: PaginationProps | boolean): pagination is PaginationProps =>
  typeof pagination === "object";

const CustomTable = <T extends Record<string, any>>({
  columns,
  dataSource,
  className = "",
  pagination = false,
  scroll,
  isLoading = false,
}: TableProps<T>) => {
  const [sortField, setSortField] = useState<keyof T | null>(null);
  const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    isPaginationProps(pagination) && pagination.defaultPageSize ? pagination.defaultPageSize : dataSource.length,
  );

  const tableHeaderRef = useRef<HTMLDivElement>(null);
  const tableBodyRef = useRef<HTMLDivElement>(null);

  const pageSizeOptions = useMemo(
    () => (isPaginationProps(pagination) && pagination.pageSizeOptions ? pagination.pageSizeOptions : [5, 10, 20]),
    [pagination],
  );

  const totalRecords = useMemo(
    () => (isPaginationProps(pagination) && pagination.totalRecords ? pagination.totalRecords : dataSource.length),
    [pagination, dataSource.length],
  );

  const handleSort = useCallback((field: keyof T, customSorter?: (a: T, b: T) => number) => {
    setSortField(prevField => {
      if (prevField === field) {
        setSortOrder(prevOrder => (prevOrder === "ascend" ? "descend" : prevOrder === "descend" ? null : "ascend"));
        return prevField;
      }
      setSortOrder("ascend");
      return field;
    });
  }, []);

  const sortedData = useMemo(() => {
    if (!sortField || !sortOrder) return dataSource;

    return [...dataSource].sort((a, b) => {
      const column = columns.find(col => col.dataIndex === sortField);
      if (column && typeof column.sorter === "function") {
        return sortOrder === "ascend" ? column.sorter(a, b) : column.sorter(b, a);
      }

      const aValue = a[sortField];
      const bValue = b[sortField];

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "ascend" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }

      if (aValue < bValue) return sortOrder === "ascend" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "ascend" ? 1 : -1;
      return 0;
    });
  }, [dataSource, sortField, sortOrder, columns]);

  const getColumnStyle = useCallback((column: MyColumnType<T>) => {
    const style: React.CSSProperties = {};
    if (column.width) {
      style.width = typeof column.width === "number" ? `${column.width}px` : column.width;
      style.maxWidth = style.width;
    }
    return style;
  }, []);
  const totalPages = useMemo(() => Math.ceil(totalRecords / pageSize), [totalRecords, pageSize]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage > 0 && newPage <= totalPages) {
        setCurrentPage(newPage);
        if (pagination && isPaginationProps(pagination) && pagination.onPageChange) {
          pagination.onPageChange(newPage);
        }
      }
    },
    [pagination, totalPages],
  );
  const handlePageSizeChange = useCallback(
    (newSize: number) => {
      setPageSize(newSize);
      if (pagination && isPaginationProps(pagination) && pagination.onPageSizeChange) {
        pagination.onPageSizeChange(newSize);
      }
    },
    [pagination],
  );

  const tableContainerStyles = useMemo(() => {
    const styles: React.CSSProperties = {
      position: "relative",
    };
    return styles;
  }, []);

  const tableBodyStyles = useMemo(() => {
    const styles: React.CSSProperties = {
      overflowX: "auto",
      overflowY: scroll?.y ? "auto" : "visible",
      maxHeight: scroll?.y ? `${scroll.y}px` : undefined,
    };
    return styles;
  }, [scroll]);

  const tableStyles = useMemo(() => {
    const styles: React.CSSProperties = {
      tableLayout: "fixed",
      width: scroll?.x ? (typeof scroll.x === "number" ? `${scroll.x}px` : scroll.x) : "100%",
    };
    return styles;
  }, [scroll]);

  // Sync the scroll between header and body
  const syncScroll = () => {
    if (tableHeaderRef.current && tableBodyRef.current) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  useEffect(() => {
    if (tableBodyRef.current) {
      tableBodyRef.current.addEventListener("scroll", syncScroll);
    }

    return () => {
      if (tableBodyRef.current) {
        tableBodyRef.current.removeEventListener("scroll", syncScroll);
      }
    };
  }, []);

  return (
    <div className={`${className}`} style={tableContainerStyles}>
      {/* Fixed Header in a scrollable div */}
      <div ref={tableHeaderRef} style={{ overflowX: "hidden" }}>
        <table className="w-full divide-y divide-gray-200" style={tableStyles}>
          <thead className="bg-gray-50">
            <tr>
              {columns.map((column, index) => (
                <th
                  key={column.key + index}
                  className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  style={getColumnStyle(column)}
                  onClick={() => column.sorter && handleSort(column.dataIndex)}
                >
                  <div className="flex items-center space-x-2">
                    <span>{column.title}</span>
                    {column.sorter && (
                      <span className="flex flex-col">
                        <CaretUpOutlined
                          className={
                            sortField === column.dataIndex && sortOrder === "ascend" ? "text-blue-500" : "text-gray-400"
                          }
                        />
                        <CaretDownOutlined
                          className={
                            sortField === column.dataIndex && sortOrder === "descend"
                              ? "text-blue-500"
                              : "text-gray-400"
                          }
                        />
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
        </table>
      </div>

      {/* Scrollable Body */}
      <div ref={tableBodyRef} style={tableBodyStyles}>
        <table className="w-full divide-y divide-gray-200" style={tableStyles}>
          <tbody className="bg-white divide-y divide-gray-200">
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="p-2">
                  <Skeleton active paragraph={{ rows: 10 }} />
                </td>
              </tr>
            ) : sortedData.length > 0 ? (
              sortedData.map((record, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                  {columns.map(column => (
                    <td
                      key={column.key}
                      className={`px-6 py-4 ${column.className || ""} ${column.align ? `text-${column.align}` : ""}`}
                      style={getColumnStyle(column)}
                    >
                      {column.render ? column.render(record[column.dataIndex], record) : record[column.dataIndex]}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {pagination && isPaginationProps(pagination) && (
        <div className="flex flex-col sm:flex-row items-center justify-between py-3 space-y-3 sm:space-y-0 sm:space-x-4">
          {pagination.showPageSizeOptions && (
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-600">Rows per page:</span>
              <Select
                value={pageSize}
                onChange={value => handlePageSizeChange(value)}
                className="ml-2"
                options={pageSizeOptions.map(size => ({
                  label: `${size}`,
                  value: size,
                }))}
                style={{ width: 80 }}
              />
            </div>
          )}
          <div className="flex items-center space-x-4">
            <Button
              type="default"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="text-sm"
              icon={<LeftOutlined />}
            />
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <Button
              type="default"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="text-sm"
              icon={<RightOutlined />}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTable;
