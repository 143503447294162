import React, { useMemo } from "react";

import useResponsive from "@/app/hooks/useResponsive";
import DisplaySize from "@/app/utils/DisplaySize";
import type { RingProgressConfig } from "@ant-design/plots";
import dynamic from "next/dynamic";

interface InflowOutflowProps {
  accuracy: number;
}
const RingProgress = dynamic(() => import("@ant-design/plots").then(mod => mod.RingProgress), {
  ssr: false,
});
const RingProgressChart: React.FC<InflowOutflowProps> = ({ accuracy }) => {
  const screenSize = useResponsive();
  const size = useMemo(() => {
    if (screenSize <= DisplaySize.MobileM) {
      return 120;
    } else if (screenSize >= DisplaySize.ComputerM) {
      return 182;
    } else {
      return 141;
    }
  }, [screenSize]);

  const config: RingProgressConfig = useMemo(
    () => ({
      height: size,
      width: size,
      autoFit: true,
      percent: accuracy / 100,
      color: ["#0E9F6E", "#E5E7EB"],
      innerRadius: 0.75,
      radius: 0.98,
      progressStyle: {
        lineCap: "round",
      },
      statistic: {
        content: {
          style: {
            color: "#0E9F6E",
            fontSize: "24px",
            lineHeight: "44px",
          },
          formatter: datum => {
            if (datum && typeof datum.percent === "number") {
              return `${(datum.percent * 100).toFixed(0)}%`;
            }
            return "0%";
          },
        },
      },
    }),
    [accuracy, size],
  );

  return <RingProgress {...config} className="p-0 sm:p-3" />;
};

export default RingProgressChart;
