"use client";
import React from "react";
import useAxiosQuery from "@/hooks/useMyQuery";
import PredictionCard from "./PredictionCard";
import type { IPredictionResponse } from "@/models/prediction";
import { CLOUDFRONT_URL } from "@/constant/apiPath";
import MyCard from "./MyCard";
import { useTranslations } from "next-intl";
import LinkToKlardaApp from "./LinkToKlardaApp";
import { Skeleton } from "antd/lib";

const KlardaPrediction: React.FC = () => {
  const t = useTranslations("home");
  const { data, isLoading } = useAxiosQuery<IPredictionResponse>({
    url: `${CLOUDFRONT_URL}/v1/market-movement/forecasting`,
    method: "get",
  });

  const handleBuy = () => {
    console.log("Buy button clicked");
  };

  const handleSell = () => {
    console.log("Sell button clicked");
  };

  const renderContent = () => {
    if (isLoading) {
      return <Skeleton active paragraph={{ rows: 5 }} className="mt-2" />;
    }

    return (
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        {data?.map((prediction, index) => (
          <PredictionCard
            key={index}
            title={prediction.tokens.name}
            subTitle="Prediction Accuracy"
            symbol={prediction.tokens.symbol}
            accuracy={prediction.accuracy}
            onBuy={handleBuy}
            onSell={handleSell}
            time={prediction.timeframe}
            status={prediction.status}
            forecast={prediction.forecast}
            price={prediction.price}
            logo={prediction.tokens.logo_uri}
          />
        ))}
      </div>
    );
  };

  return (
    <MyCard title={<div className="uppercase">{t("Klarda_Prediction")}</div>} extra={<LinkToKlardaApp />}>
      {renderContent()}
    </MyCard>
  );
};

export default KlardaPrediction;
