import React, { useCallback } from "react";
import MyCard from "./MyCard";
import { Avatar } from "antd/lib";
import type { PredictionStatus } from "@/models/prediction";
import PredictionTimeCard from "@/app/components/PredictionTimeCard";
import PredictionRingProgressCard from "@/app/components/PredictionRingProgressCard";
import LinkToKlardaApp from "./LinkToKlardaApp";
import { useTranslations } from "next-intl";
import TokenPrice from "@/app/components/TokenPrice";
import { useRouter } from "next/navigation";
import { useLanguageContext } from "@/context/LanguageContext";

interface PredictionCardProps {
  title: string;
  subTitle: string;
  accuracy: number;
  symbol: string;
  time: string;
  status: PredictionStatus;
  forecast: number;
  price: number;
  logo: string;
  onBuy: () => void;
  onSell: () => void;
}

const PredictionCard: React.FC<PredictionCardProps> = ({
  title,
  subTitle,
  accuracy,
  symbol,
  time,
  status,
  forecast,
  logo,
  onBuy,
  price,
  onSell,
}) => {
  const t = useTranslations("home");
  const router = useRouter();
  const { language } = useLanguageContext();

  const handleClickToken = useCallback(() => {
    router.push(`/${language}/markets/${symbol}`);
  }, [language, router, symbol]);

  return (
    <MyCard
      className="bg-white shadow-lg w-full rounded-lg overflow-hidden p-3 border border-black border-opacity-10  hover:shadow-xl"
      styles={{
        header: {
          border: "none",
          gap: "24px",
        },
        body: {
          padding: "0px",
        },
      }}
      extra={<LinkToKlardaApp isOnlyText url={`/${language}/markets/${symbol}`} label={t("View_detail")} />}
      title={
        <div className="flex items-center cursor-pointer" onClick={handleClickToken}>
          <Avatar src={logo} alt={`${title} avatar`} className="mr-2" size="small" />
          <div className="flex gap-2 items-center">
            <p className="font-semibold m-0 text-sm">{title}</p>
            <p className="text-gray-500 m-0 text-sm">{symbol}</p>
            <TokenPrice
              className="text-sm font-normal"
              symbol={symbol as string}
              initPrice={price ? price : (0 as number)}
            />
          </div>
        </div>
      }
    >
      <div className="flex gap-4 items-center flex-row">
        {/* chart */}
        <PredictionRingProgressCard subTitle={subTitle} accuracy={accuracy} onBuy={onBuy} onSell={onSell} />
        {/* card details */}
        <PredictionTimeCard price={price} time={time} forecast={forecast} status={status} />
      </div>
    </MyCard>
  );
};

export default PredictionCard;
