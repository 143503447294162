"use client";

import Heading from "@/app/components/Heading";
import MyCard from "@/app/components/MyCard";
import { DateTimeFormat } from "@/constant";
import { formatThousands } from "@/helpers";
import useAxiosQuery from "@/hooks/useMyQuery";
import type { ISignal, ISignalRes } from "@/models/signal";
import Skeleton from "antd/lib/skeleton";
import Typography from "antd/lib/typography";
import dayjs from "dayjs";
import { useTranslations } from "next-intl";
import React, { useCallback, useMemo } from "react";
import type { MyColumnType } from "@/app/components/MyTable";
import CustomTable from "@/app/components/MyTable";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { CLOUDFRONT_URL } from "@/constant/apiPath";
import { useRouter } from "next/navigation";
import { useTimezoneContext } from "@/context/TimezoneContext";
import { useCurrencyContext } from "@/context/CurrencyContext";
import { useLanguageContext } from "@/context/LanguageContext";

export const TokenInfo = React.memo(({ data }: { data?: ISignal }) => {
  const router = useRouter();
  const { currentCurrency } = useCurrencyContext();
  const { language } = useLanguageContext();
  const handleClickToken = useCallback(
    (symbol: string) => {
      router.push(`/${language}/markets/${symbol}`);
    },
    [language, router],
  );
  return (
    <div className="flex items-center space-x-4">
      <div className="flex-shrink-0 cursor-pointer" onClick={() => handleClickToken(data?.symbol ?? "")}>
        <img src={data?.logo_url} alt={`${data?.symbol} logo`} width={32} height={32} className="rounded-full" />
      </div>
      <div className="flex flex-col">
        <div className="text-[14px] text-black cursor-pointer" onClick={() => handleClickToken(data?.symbol ?? "")}>
          <Typography.Text strong>{data?.symbol}</Typography.Text>
        </div>
        <div className="text-[14px]">
          <Typography.Text strong>{formatThousands(data?.price, currentCurrency)}</Typography.Text>
        </div>
      </div>
    </div>
  );
});

TokenInfo.displayName = "TokenInfo";

function Signals() {
  const t = useTranslations("home");
  const { timezone } = useTimezoneContext();

  const { data: signalsRes, isLoading: isLoadingSignals } = useAxiosQuery<ISignalRes | undefined>({
    url: `${CLOUDFRONT_URL}/v1/market-movement/signal/simple-list`,
    method: "get",
  });

  const columnsSignal = useMemo<MyColumnType<ISignal>[]>(
    () => [
      {
        title: t("Time"),
        key: "signal_time",
        dataIndex: "signal_time",
        className: "h-[42.6px]",
        width: 200,
        render: (_, record) => {
          const day = dayjs(record.signal_time).tz(timezone.utc);
          return (
            <Typography.Text title={record.signal_time}>
              {day.isBefore(dayjs().subtract(24, "h")) ? day.format(DateTimeFormat) : day.fromNow()}
            </Typography.Text>
          );
        },
      },
      {
        title: t("Asset"),
        key: "asset",
        dataIndex: "asset",
        width: 200,
        className: "h-[42.6px]",
        sorter: (a: ISignal, b: ISignal) => a.name.localeCompare(b.name),
        render: (_, record) => <TokenInfo data={record} />,
      },
      {
        title: t("Buy_sell"),
        key: "buy_sell",
        className: "h-[42.6px]",
        dataIndex: "buy_sell",
        sorter: (a: ISignal, b: ISignal) => (a.buy_sell === b.buy_sell ? 0 : a.buy_sell ? -1 : 1),
        width: 150,
        align: "left",
        render: (_, record) => (
          <Typography.Text type={record.buy_sell ? "success" : "danger"}>
            {record.buy_sell ? t("Buy") : t("Sell")}
          </Typography.Text>
        ),
      },
      {
        title: t("Back_test"),
        key: "confirm",
        className: "h-[42.6px]",
        dataIndex: "confirm",
        align: "left",
        width: 150,
        sorter: (a: ISignal, b: ISignal) => a.confirm - b.confirm,
        render: (_, record) => (
          <Typography.Text type={record.confirm ? "success" : "danger"} className="w-[150px]">
            {record.confirm ? (
              <span className="flex gap-1">
                <CheckCircleOutlined />
                {t("Correct")}
              </span>
            ) : (
              <span className="flex gap-1">
                <CloseCircleOutlined />
                {t("Missed")}
              </span>
            )}
          </Typography.Text>
        ),
      },
      {
        title: t("Signal"),
        key: "signal",
        className: "h-[42.6px]",
        dataIndex: "signal",
        align: "left",
        width: 200,
        sorter: (a: ISignal, b: ISignal) => a.signal.localeCompare(b.signal),
        render: text => (
          <Typography.Text className="w-[200px]" type={text}>
            {text}
          </Typography.Text>
        ),
      },
    ],
    [t, timezone.utc],
  );

  return (
    <MyCard className="h-full lg:col-span-8" title={<Heading title={t("Signals")} icon={null} />}>
      {isLoadingSignals ? (
        <Skeleton active paragraph={{ rows: 10 }} />
      ) : (
        <>
          <CustomTable dataSource={signalsRes?.data?.result || []} columns={columnsSignal} scroll={{ x: 950 }} />
        </>
      )}
    </MyCard>
  );
}

export default React.memo(Signals);
