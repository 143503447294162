"use client";
import Slider from "react-slick";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Card, Skeleton, Typography } from "antd/lib";
import useAxiosQuery from "@/hooks/useMyQuery";
import NewsInfo from "@/app/components/NewsInfo";
import { CLOUDFRONT_URL } from "@/constant/apiPath";
import type { INewsResponse, NewsResponseCDN } from "@/models/news";
import { processNewsData } from "@/helpers";
import MyCard from "@/app/components/MyCard";
import { useTranslations } from "next-intl";
import { createSliderSettings } from "@/app/components/slider/SliderSettings";
import LinkToKlardaApp from "./LinkToKlardaApp";
import { useLanguageContext } from "@/context/LanguageContext";

const TopNews = React.memo(({ title, subTitle }: { title: string; subTitle: string }) => {
  const mainSliderRef = useRef<Slider | null>(null);
  const secondarySliderRef = useRef<Slider | null>(null);
  const [activeMainSlide, setActiveMainSlide] = useState(0);
  const [activeSecondarySlide, setActiveSecondarySlide] = useState(0);
  const t = useTranslations("home");
  const { language } = useLanguageContext();

  const { data: newsData, isLoading: isLoadingMain } = useAxiosQuery<NewsResponseCDN>({
    url: `${CLOUDFRONT_URL}/v1/market-insight/news/is-top`,
    method: "get",
  });

  const { data: bottomNewsData, isLoading: isLoadingSecondary } = useAxiosQuery<NewsResponseCDN>({
    url: `${CLOUDFRONT_URL}/v1/market-insight/news`,
    method: "get",
  });

  const mainSliderSettings = useMemo(
    () => createSliderSettings(mainSliderRef, activeMainSlide, setActiveMainSlide, 1),
    [activeMainSlide],
  );

  const secondarySliderSettings = useMemo(
    () => createSliderSettings(secondarySliderRef, activeSecondarySlide, setActiveSecondarySlide, 3),
    [activeSecondarySlide],
  );

  const renderMainNewsCard = useCallback(
    (news: INewsResponse) => {
      const { description, title } = processNewsData(news);

      return (
        <div key={news.id} className="flex flex-col lg:flex-row">
          <div className="w-full lg:pr-4 mb-2 lg:mb-3">
            <div className="space-y-1">
              <Typography.Link
                title={title}
                href={news.is_klarda ? `/${language}/news/${news.link}` : `/${language}/news/${news.id}`}
              >
                <Typography.Title level={5} className="line-clamp-3">
                  <div dangerouslySetInnerHTML={{ __html: title }} />
                </Typography.Title>
              </Typography.Link>
              <Typography.Link
                href={news.is_klarda ? `/${language}/news/${news.link}` : `/${language}/news/${news.id}`}
              >
                <Typography.Text title={description} type="secondary" className="line-clamp-4">
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </Typography.Text>
              </Typography.Link>
            </div>
            <div className="flex items-center mt-4">
              <NewsInfo news={news} />
            </div>
          </div>
        </div>
      );
    },
    [language],
  );

  const renderSecondaryNewsCard = useCallback(
    (news: INewsResponse, index: number) => {
      const { description, title, imageUrl } = processNewsData(news);

      return (
        <div key={index} className="py-2 px-1">
          <Card
            styles={{
              body: {
                padding: 8,
              },
              cover: { padding: 8 },
            }}
            hoverable
            cover={
              <img
                onError={e => {
                  e.currentTarget.src = "https://d37pr5wbjsk01d.cloudfront.net/1725547867815.png";
                }}
                className="rounded-lg h-[200px]"
                src={imageUrl as string}
                alt={news.title || "News Image"}
              />
            }
          >
            <Typography.Link
              title={title}
              href={news.is_klarda ? `/${language}/news/${news.link}` : `/${language}/news/${news.id}`}
            >
              <Typography.Title level={5} className="line-clamp-2">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </Typography.Title>
            </Typography.Link>
            <Typography.Text title={description} type="secondary" className="line-clamp-2">
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </Typography.Text>
            <div className="flex items-center mt-4">
              <NewsInfo news={news} />
            </div>
          </Card>
        </div>
      );
    },
    [language],
  );

  const slidesData = useMemo(
    () => bottomNewsData?.news?.map(renderSecondaryNewsCard),
    [bottomNewsData, renderSecondaryNewsCard],
  );

  const sanitizedNews = useMemo(() => newsData?.news?.map(renderMainNewsCard), [newsData, renderMainNewsCard]);

  return (
    <MyCard
      title={title}
      extra={<LinkToKlardaApp isOnlyText label={"More news >"} url={"/news"} />}
      className="lg:col-span-8"
      styles={{ header: { border: "none" }, body: { padding: 0 } }}
    >
      {isLoadingMain ? (
        <Skeleton active paragraph={{ rows: 8 }} />
      ) : (
        <MyCard title={subTitle} size="small">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
            <div className="col-span-1 md:col-span-5 order-2 md:order-1">
              <Slider {...mainSliderSettings} ref={mainSliderRef} className="p-2">
                {sanitizedNews}
              </Slider>
            </div>
            <div className="col-span-1 md:col-span-7 order-1 md:order-2 mb-4 md:mb-0">
              <a
                href={
                  newsData?.news[activeMainSlide].is_klarda
                    ? `/${language}/news/${newsData?.news[activeMainSlide].link}`
                    : `/${language}/news/${newsData?.news[activeMainSlide].id}`
                }
              >
                <img
                  src={
                    newsData?.news[activeMainSlide]?.thumbnail ||
                    "https://d37pr5wbjsk01d.cloudfront.net/1725547867815.png"
                  }
                  alt={newsData?.news[activeMainSlide]?.title || "News Image"}
                  className="w-full h-48 md:h-full object-cover rounded-lg"
                />
              </a>
            </div>
          </div>
        </MyCard>
      )}
      {isLoadingSecondary ? (
        <Skeleton active paragraph={{ rows: 3 }} className="mt-2" />
      ) : (
        <MyCard title={t("Latest_news")} size="small" className="mx-1 mt-2">
          <Slider {...secondarySliderSettings} ref={secondarySliderRef}>
            {slidesData}
          </Slider>
        </MyCard>
      )}
    </MyCard>
  );
});
TopNews.displayName = "TopNews";

export default TopNews;
