import React from "react";
import RingProgressChart from "@/app/components/charts/RingProgress";
import ButtonBuySell from "@/app/components/ButtonBuySell";

interface PredictionRingProgressCardProps {
  subTitle: string;
  accuracy: number;
  onBuy?: () => void;
  onSell?: () => void;
}

const PredictionRingProgressCard: React.FC<PredictionRingProgressCardProps> = ({
  subTitle,
  accuracy,
  onBuy,
  onSell,
}) => (
  <div className="flex flex-col gap-6 flex-1">
    <div className="flex flex-col items-center">
      <p className="text-[12px] sm:text-sm text-gray-500 mb-2 text-nowrap">{subTitle}</p>
      <RingProgressChart accuracy={accuracy} />
    </div>
    <ButtonBuySell onSell={onSell} onBuy={onBuy} />
  </div>
);
export default PredictionRingProgressCard;
