import React from "react";
import type Slider from "react-slick";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const NavigationButtons = React.memo(({ onPrev, onNext }: { onPrev: () => void; onNext: () => void }) => (
  <div className="gap-2 flex">
    <Button icon={<LeftOutlined style={{ color: "white" }} />} className="rounded-full bg-gray-500" onClick={onPrev} />
    <Button icon={<RightOutlined style={{ color: "white" }} />} className="rounded-full bg-gray-500" onClick={onNext} />
  </div>
));
NavigationButtons.displayName = "NavigationButtons";
const customPaging = (i: number, activeSlide: number) => (
  <div className={`h-1 w-full rounded-md mx-1 ${i === activeSlide ? "bg-primary" : "bg-gray-300"}`} />
);
export const createSliderSettings = (
  sliderRef: React.RefObject<Slider>,
  activeSlide: number,
  setActiveSlide: React.Dispatch<React.SetStateAction<number>>,
  slidesToShow = 1,
) => ({
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow,
  slidesToScroll: 1,
  beforeChange: (_current: number, next: number) => setActiveSlide(next),
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  appendDots: (dots: React.ReactNode[]) => {
    const maxDots = 12;
    const limitedDots = React.Children.toArray(dots).slice(0, maxDots);

    return (
      <>
        <div className="flex items-center">
          <NavigationButtons
            onPrev={() => sliderRef.current?.slickPrev()}
            onNext={() => sliderRef.current?.slickNext()}
          />
          <ul className="flex gap-1 m-0 p-0 flex-grow">{dots.length <= maxDots ? dots : limitedDots}</ul>
        </div>
      </>
    );
  },
  customPaging: (i: number) => customPaging(i, activeSlide),
});
